import React from "react"
import { Helmet } from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import Styled from 'styled-components';
// import Projects from "../components/projects";
import Ocean from "../images/oceanblue.jpeg";
// import '../styles/global.css';
import CurrentlyCurious from '../components/currentlyCurious';
import CurrentlyExploring from '../components/currentlyExploring';
import CurrentlyBuilding from "../components/currentlyBuilding"
import Subscribe from "../components/subscribe"


const Styles = Styled.section`
  position: absolute;
  max-width: 100%;
  min-width: 100%;
  min-height: 100vh;
  // cursor: url("https://i.ibb.co/pvDZpdt/Screen-Shot-2021-10-27-at-11-49-41-PM-removebg-preview.png"), default;
  background-color: #faf9f2;
  display: flex;
  align-items: center;

  .anti {
    // position: absolute;
    padding: none;
  }
  .block {
    background-color: #faf9f2;
    display: flex;
    height: auto;
    padding-top: 100px;
    // margin-bottom: 4vh;
    flex-flow: column;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    align-self: center;
  }
  .inside {
    margin: auto;
  }

  h3 {
    word-wrap: normal;
    font-family: 'Space Grotesk', sans-serif;
  }
  .header-2 {
    padding: 5vh;
  }

  a {
    text-decoration: none;
    color: black;
  }
  .mainContent {
    // display: inline-block;
    display: flex;
    flex-direction: column;
    flex-flow: column;
    margin-top: -100px;
    padding-left: 25vw;
    padding-right: 25vw;
    text-align: left;
    justify-content: center;
    align-content: center;
  }
  @media only screen and (max-width: 1200px) {
    // display: block;
    .block {
      padding-top: 160px;
    }
    .mainContent {
      padding-left: 20vw;
      padding-right: 18vw;
    }
    h1 {
      font-size: 4rem;
    }
  }
  @media only screen and (max-width: 850px) {
    .block {
      padding-top: 160px;
    }
    .mainContent {
      padding-left: 20vw;
      padding-right: 20vw;
    }
    h1 {
      font-size: 4rem;
    }
  }
  @media only screen and (max-width: 700px) {
    // display: block;

    .block {
      padding-top: 160px;
    }
    .mainContent {
      padding-left: 20vw;
      padding-right: 20vw; 
    }
    h1 {
      font-size: 4rem;
    }
  }
  @media only screen and (max-width: 600px) {
    // display: block;

    .block {
      padding-top: 160px;
    }
    .mainContent {
      padding-left: 10vw;
      padding-right: 10vw; 
    }
    h1 {
      font-size: 4rem;
    }
  }

  .callout {
    padding: 15px;
    background-color: #f9f9fa;
    transition: 0.5s ease;
  }

  .callout:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    transition: 0.5s ease;
  }

  .quote {
    padding-left: 30px;
    border-left: 4px solid black;
  }

  .currently {
    font-size: 15px;
    line-height: 20px;
  }
  .currentlyCurious {
    font-size: 15px;
    line-height: 20px;
  }

  p {
    font-size: 15px;
  }
  .cta {
    padding: 10px;
    transition: 0.5s ease;
    text-shadow: none;
  }
  .cta:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    transition: 0.5s ease;
  }

  .headerImage {
    display: inline-block;
    min-height: 250px;
    max-height:250px;
    min-width: 100%;
    object-fit: cover;
  }
  h1 {
    margin-top: 4vh;
  }
  .title {
    font-family: 'Shrikhand', cursive;
  }
  h4 {
    font-family: 'Space Grotesk', sans-serif;
  }
  // .frontFooter {
  //   position : absolute;
  //   bottom : 10px;
  //   align-self: center;
  // }

  .text {
    align-self: center;
  }
`;

const IndexPage = () => (
    // <SEO title="Home" />
    <Styles>
    <SEO title='Home | Vadini' description="Vadini's landing page 🎊" link="https://www.vadiniagrawal.com"/>
    <div className="anti">
      <Header />
    </div>
    {/* <div className="headerImage">
      <img className="headerImage" src={Ocean}/> 
    </div> */}
    <div className="block">
      <div className="mainContent">
        <div className="text">
          <h1 className="title"> hi! i'm vadini. </h1>
          {/* <h5 className="callout"> <span className="emoji">🎊 </span> Welcome to my landing page. </h5> */}
          <h2> <b>I'm a technologist.</b> </h2> 
          {/* <p className="quote"> Looking for full-time software engineering roles at small to mid-size mission driven companies. See my resume <a href="https://drive.google.com/file/d/1uyLasmraQgtwjO4AcworwWKFFF2fSjgr/view?usp=sharing" target="_blank"><u>here</u></a>.</p> */}
          <h3> I'm currently a software engineer at Oscar Health. </h3>
          {/* <h3> I also occasionally write.</h3> */}
          {/* { <CurrentlyBuilding /> }
          { <CurrentlyCurious />}
          { <CurrentlyExploring />} */}
          {/* <div className="cta">
            <Link to="/writing"><b>Check out my writing &#8594;</b></Link>
          </div> */}
          {/* <Subscribe /> */}
        </div>
      </div>
      <div className="frontFooter">
          <Footer />
      </div>
	  </div>
    </Styles>
)

export default IndexPage
